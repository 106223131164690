@tailwind base;

@tailwind utilities;

@layer base {
  /* Basic style for html */

  body {
    @apply p-0 m-0 relative bg-basic;
    font-family: "sans-serif", "roboto";
  }

  main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  h1 {
    text-shadow: 0 0 16px #fff, 0 0 32px #fff, 0 0 64px #fff;
    font-family: "Dancing Script", "sans-serif", "roboto";
    @apply text-6xl font-extrabold text-d text-center;
  }

  h2 {
    @apply text-4xl text-center text-d;
  }

  h3 {
    @apply text-4xl text-center text-d;
  }

  h4 {
    @apply text-slate-100 text-3xl;
  }

  h5 {
    @apply text-slate-100 text-2xl;
  }

  h6 {
    @apply text-slate-100 text-xl;
  }

  p,
  address {
    font-family: "sans-serif", "roboto";
    @apply text-slate-100 text-lg font-medium;
  }

  [type="password"],
  [type="text"],
  [type="email"],
  textarea {
    @apply w-full resize-none h-14 rounded-lg border-2 border-d
    focus:border-black focus:ring-black px-1
    shadow-sm placeholder-transparent text-d;
  }

  label {
    @apply font-normal absolute z-10 left-2 -top-3  text-xs rounded-full py-1 px-2 bg-white
    peer-placeholder-shown:text-base peer-placeholder-shown:top-3.5 peer-placeholder-shown:bg-transparent
    transition-all peer-focus:-top-3 peer-focus:text-xs peer-focus:bg-white text-d;
  }

  /* Basic style for html end */
}

@tailwind components;

@layer components {
  /* Background images for random backgrounds */

  .bg-1 {
    background-image: url("/public/bg1.jpg");
  }

  .bg-2 {
    background-image: url("/public/bg2.jpg");
  }

  .bg-3 {
    background-image: url("/public/bg3.jpg");
  }

  .bg-4 {
    background-image: url("/public/bg4.jpg");
  }

  .bg-5 {
    background-image: url("/public/bg5.jpg");
  }

  .bg-6 {
    background-image: url("/public/bg6.jpg");
  }

  .bg-7 {
    background-image: url("/public/bg7.jpg");
  }

  /* Background images for random backgrounds end */

  /* General setups for several page elements */

  .bg-setup {
    @apply bg-fixed bg-center bg-cover bg-no-repeat py-40 md:py-48 lg:py-48 
    w-full flex flex-col items-center justify-start gap-6 md:gap-12;
  }

  .story-bg {
    position: relative;
  }

  .story-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--background);
    filter: grayscale(100%);
    @apply bg-fixed bg-center bg-cover bg-no-repeat py-40 md:py-48 lg:py-48 
    w-full flex flex-col items-center justify-start gap-6 md:gap-12;
  }

  .card-setup {
    @apply flex flex-col min-w-[320px] 
    items-center px-4 md:px-12 rounded-xl shadow-lg bg-b/95;
  }

  .button-setup {
    @apply flex flex-row py-3 px-6 gap-6 justify-center items-center
    font-medium rounded cursor-pointer ease-in-out duration-300;
  }

  .svg-setup {
    @apply w-6 transition-colors duration-300
   cursor-pointer opacity-70 fill-basic hover:opacity-100;
  }

  .button-setup:hover .svg-setup {
    @apply fill-d;
  }

  /* General setups for several page elements end */

  /* Style for buttons */

  .button-effect-success:hover {
    filter: drop-shadow(1px 1px 24px rgb(101 193 140 / 2));
  }

  .button-effect-select:hover {
    filter: drop-shadow(1px 1px 24px rgb(0 102 204 / 2));
  }

  .button-effect-error:hover {
    filter: drop-shadow(1px 1px 24px rgb(245 113 112 / 2));
  }

  .strikediag {
    padding: 0 0.15em;
    background: linear-gradient(
      to left top,
      transparent 47%,
      white 47%,
      white 52%,
      transparent 52%
    );
  }

  .button-setup:hover .strikediag {
    background: linear-gradient(
      to left top,
      transparent 47%,
      #1d1d1f 47%,
      #1d1d1f 52%,
      transparent 52%
    );
  }

  /* Style for buttons end */

  /* Spacing */

  .gap-form {
    @apply gap-4 md:gap-6 lg:gap-8;
  }

  .gap-image {
    @apply gap-4 md:gap-6 lg:gap-8;
  }

  .py-form {
    @apply py-4 md:py-8 lg:py-10;
  }

  /* Spacing end */

  /* Image grid style */

  .image-grid {
    @apply grid gap-4 grid-cols-12;
  }

  /* Image grid style end */

  /* Overall styles */

  .navhover:hover {
    text-shadow: 0 0 8px #fff, 0 0 16px #fff, 0 0 32px #fff;
  }

  .nav-active {
    text-shadow: 0 0 8px #fff, 0 0 16px #fff, 0 0 32px #fff;
  }

  .navsvghover:hover {
    filter: drop-shadow(0.5px 0.5px 12px rgb(255 255 255 / 1));
  }

  .shadow-sub {
    box-shadow: 0 0 32px #fff;
  }

  /* Overall styles end */
}
